<template>
  <a
    :id="id"
    class="pointer-events-none invisible absolute"
    :style="{marginTop: `${-headerOffset}px`}"
  />
</template>

<script setup lang="ts">
import {useHeaderOffset} from '~/composables/useHeaderOffset';

defineProps<{ id: string }>();

const {headerOffset} = useHeaderOffset();
</script>
